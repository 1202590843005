import {Router} from '@angular/router';
import {ProcessChallengeResult} from '../challenge/challenge-service';

export function redirectBasedOnChallegne(
  processResult: ProcessChallengeResult,
  router: Pick<Router, 'navigateByUrl'>,
): void {
  if (processResult.challenge?.length) {
    if (processResult.challenge.length > 1) {
      router.navigateByUrl('/account/plugins');
    } else if (processResult.challenge.includes('datafeed_api')) {
      router.navigateByUrl('/pipelines/add?source=datafeed_api');
    } else if (processResult.challenge.includes('repricing_api')) {
      router.navigateByUrl('/repricing/setup');
    } else if (processResult.challenge.includes('google_spd_api')) {
      router.navigateByUrl('/google/gad');
    }
  }
}
