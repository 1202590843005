import {Router} from '@angular/router';
import {SetupStatusService} from '@em/auth/data-access';
import {Observable, filter, map, of, switchMap, take, tap} from 'rxjs';
import {redirectBasedOnChallegne} from './redirect-based-on-challenge';
import {Injectable, inject} from '@angular/core';
import {
  NotificationsService,
  WORKFLOW_COMPLETED_NOTIFICATION_TYPES,
} from '@em/server-notifications';
import {PartnerService} from '@em/shared/white-label';
import {ProcessChallengeResult} from '../challenge/challenge-service';
import {RedirectUrlStorageService} from '@em/shared/util-web';

@Injectable({
  providedIn: 'root',
})
export class RedirectAfterOnboardingService {
  private readonly _router = inject(Router);
  private readonly _setupStatusService = inject(SetupStatusService);
  private readonly _serverNotificationsService = inject(NotificationsService);
  private readonly _partnerService = inject(PartnerService);
  private readonly _redirectUrlStorageService = inject(
    RedirectUrlStorageService,
  );

  redirect(processResult: ProcessChallengeResult): Observable<boolean> {
    const explicitRedirectUrl = this._redirectUrlStorageService.getUrl();

    // challenge exist
    if (processResult.challenge?.length) {
      redirectBasedOnChallegne(processResult, this._router);
      return of(true);
    } else if (explicitRedirectUrl) {
      // If explicitRedirectUrl is defined, force redirect to that URL
      // This url is set before user left our app, and once he is back he should be redirected back to it.

      // Navigate to explicitRedirectUrl
      this._redirectUrlStorageService.navigateToStoredUrl();

      return of(true);
    } else if (processResult.type === 'emptyChallengePosted') {
      return this._partnerService.observable().pipe(
        switchMap((partner) => {
          if (partner.key === 'webstollen') {
            // for webstollen redirect to plugins, both plugins will be activated on registration
            this._router.navigateByUrl('/account/plugins');
            return of(true);
          } else {
            // when empty challenge posted we need to wait till the backend finish authorization
            // check the setup status after wizard completion to determine where the user came from
            return this._serverNotificationsService
              .typedWorkflowCompletedNotification(
                WORKFLOW_COMPLETED_NOTIFICATION_TYPES.AuthorizationWorkflow,
              )
              .pipe(
                tap(() => this._setupStatusService.invalidateSetupStatus()),
                switchMap(() => this._setupStatusService.isOutdated$),
                filter((outdated) => !outdated),
                switchMap(() => this._setupStatusService.observable()),
                take(1),
                tap((setupStatus) => {
                  if (setupStatus.datafeed.isSetup) {
                    this._router.navigateByUrl(
                      '/pipelines/add?source=datafeed_api',
                    );
                  } else if (setupStatus.repricing.isSetup) {
                    this._router.navigateByUrl('/repricing/setup');
                  } else {
                    this._router.navigateByUrl(
                      '/pipelines/add?source=datafeed_api',
                    );
                  }
                }),
                map(() => true),
              );
          }
        }),
      );
    } else {
      return of(false);
    }
  }
}
